import React, { Component } from "react";

//Components
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//import emailjs from "emailjs-com";
import emailjs from '@emailjs/browser';
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


// Css
import "../Styles/Contact.css";



class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
      open: false,
      snackbarMsg: "",
      snackbarSeverity: true,
      isLoading: false,
    };


    this.sendMessage = this.sendMessage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  sendMessage(event) {
    event.preventDefault();
    const name = this.state.name;
    const email = this.state.email;
    const message = this.state.message;

    this.setState({isLoading: true});
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(email)){
      //Invalid email
      this.setState({
        open: true,
        snackbarMsg: "Please key in a valid email :( ",
        snackbarSeverity: "error",
        isLoading: false
      });
      return
    }

    if (name !== "" && email !== "" && message !== ""){
      const templateParams = {
        from_name: name,
        to_email: process.env.EMAIL,
        from_email: email,
        message: message,
      };


      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICEID,
          process.env.REACT_APP_EMAILJS_TEMPLATEID,
          templateParams,
          process.env.REACT_APP_EMAILJS_USERID
        )
        .then((res) => {
           console.log("Message sent!");
          this.setState({
            open: true,
            snackbarMsg: "Message has been sent !",
            snackbarSeverity: "success",
            name: "",
            email: "",
            message: "",
            isLoading: false
          });
        })
        .catch((err) => {
           console.log("ERROR !");
           console.log(err);
          this.setState({
            open: true,
            snackbarMsg: "Opps ! Something went wrong !",
            snackbarSeverity: "error",
          });
        });
    } else {
      //If nothing is being typed but button pressed
      this.setState({
        open: true,
        snackbarMsg: "Field cannot be empty ☹ ",
        snackbarSeverity: "error",
        isLoading: false
      });
    }
  }

  handleClose(evt, reason) {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  }

  handleChange(evt) {
    this.setState({
      ...this.state,
      [evt.target.name]: evt.target.value,
    });
  }

  render() {
    // const [open, setOpen] = React.useState(false);

    return (
      <div className="contact-container">
        <div className="contact-content mainContentWrappers">
          <div className="title">Get in touch</div>
          <div className="subtitle">
            Fill in the form below to get in touch with me about project
            collaborations, or just say hi !
          </div>
        <div className="form-wrapper">
            {/* <AccountCircleIcon /> */}
            <form className="contact-form">
              <input
                name="name"
                type="text"
                value={this.state.name}
                placeholder="Name here"
                className="contactTextfield"
                onChange={this.handleChange}
              />
              <input
                name="email"
                type="email"
                value={this.state.email}
                placeholder="Email here"
                className="contactTextfield"
                onChange={this.handleChange}
              />
              <textarea
                name="message"
                value={this.state.message}
                placeholder="Message here"
                onChange={this.handleChange}
                className="contactTextfield textarea"
              />
              {/* <SubmitButton onClick={this.sendMessage}> */}
              <div className="submitSpan">
              <button className="submit-btn" onClick={this.sendMessage}>
                <span>{this.state.isLoading ? 'Sending' : 'Send Message'} </span>
                <Loader type="Grid" color="white" className="loadingIndicator" height={15} width={15} visible={this.state.isLoading}/>
              </button>
              </div>
            </form>
            <div className="title2">Follow me</div>
            <div className="socialIcons">
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/SeanKzw/">
                <i className="fab fa-facebook-square"></i>
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/seankzw/">

                <i className="fab fa-instagram"></i>
              </a>
              <a
                target="_blank" rel="noreferrer"
                href="https://www.linkedin.com/in/sean-koh-6146591ab/"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MuiAlert
            onClose={this.handleClose}
            elevation={6}
            variant="filled"
            severity={this.state.snackbarSeverity}
          >
            {this.state.snackbarMsg}
          </MuiAlert>
        </Snackbar>

      </div>
    );
  }
}

export default Contact;
