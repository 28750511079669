import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import "../Styles/Navbar.css";

import logo from "../Images/logo_64px.png";

export default class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="logoWrapper">
          <NavLink to="/home">
            <img src={logo} alt="logo" className="logo" />
          </NavLink>
        </div>
        <ul className="navbar-ul">
          <li className="navbar-li">
            <Link to="/home">Home</Link>
          </li>
          <li className="navbar-li">
            <Link to="/skills">Skills</Link>
          </li>
          <li className="navbar-li">
            <Link to="/works">Works</Link>
          </li>
          <li className="navbar-li">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    );
  }
}
