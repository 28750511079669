import React, { Component } from "react";

//Components
import ParticlesBg from "particles-bg";
import { TagCloud } from "react-tagcloud";

import toolsSet from "../data/tools.js";
import mainSkills from "../data/main_skills";
//import interestSet from "../data/interest";
import languageSets from "../data/languages";
//css
import "../Styles/Skills.css";
import experienceData from "../data/experience.js";
import graphicalTools from "../data/graphic_tools.js";

import BIRDS from 'vanta/dist/vanta.birds.min';
import * as THREE from 'three';


var options = {
  luminosity: 'light',
  hue: '#E94560',
};


const sizeOfTags = 30;



class Skills extends Component {
  constructor(props){
    super(props);

    this.toolsFormatter = this.toolsFormatter.bind(this);
    this.experienceComponent = this.experienceComponent.bind(this);
  }


  //format tools to a json from array of tools
  toolsFormatter (arrOfTools) {
    let object = [];
    arrOfTools.forEach((eachTools) => {
      object.push({value: eachTools, count: sizeOfTags});
    });

    //console.table(object);

    return object;
  }

  experienceComponent (){
    return experienceData.map((eachExp) => {
      return (
        <div className="jobBox">
              {<span className="jobPos"> {eachExp['jobPosition']}</span>} <br />
              @ {eachExp['company']} <br />
              <span className="duration">{eachExp['from']} - {eachExp['to']}</span>
        </div>)
    })
  }

  render() {
    return (
      <div className="skills-container mainContentWrappers">
        <div id="skills-scroll" className="skills-wrapper">
          <div className="title skills-title">skills</div>
          <div className="title2">Main Skills</div>
          <div className="tagcloud-wrapper">
            <TagCloud
              minSize={30}
             maxSize={40}
              colorOptions = {options}
              tags={this.toolsFormatter(mainSkills)}
              className="tagCloud"
            />
          </div>
          <div className="title2">Tools & Frameworks</div>
          <div className="tagcloud-wrapper">
            <TagCloud
              minSize={30}
              maxSize={40}
              colorOptions = {options}
              tags={this.toolsFormatter(toolsSet)}
              className="tagCloud"
            />
          </div>
          <div className="title2">Graphical Tools</div>
        <div className="tagcloud-wrapper">
            <TagCloud
              minSize={30}
              maxSize={40}
              colorOptions = {options}
              tags={this.toolsFormatter(graphicalTools)}
              className="tagCloud"
            />
          </div>
            <div className="title2">Languages</div>
            <div className="tagcloud-wrapper">
              <TagCloud
                minSize={30}
                maxSize={40}
                colorOptions = {options}
                tags={this.toolsFormatter(languageSets)}
              className="tagCloud"
              />
            </div>
          <div className="title2">Experience</div>
          <div className="jobWrapper">
            {this.experienceComponent()}
          </div>
          {/*<div className="title2">Talents & Interest</div>
          <div className="tagcloud-wrapper">
            <TagCloud
              minSize={30}
              maxSize={40}
              tags={interestSet}
              className="tagCloud"
              colorOptions = {options}
            />
          </div>*/}
        </div>
        <ParticlesBg type="cobweb" num={80} color="#40141D" config={{alpha:[0.1,0.8]}} bg={true} />
      </div>
    );
  }
  //
}

export default Skills;
