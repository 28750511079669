const languageSets = [
  "Python",
  "PHP",
  "Javascript",
  "Java",
  "Dart",
  "C#",
  "HTML & CSS",
]

export default languageSets;