import React, { Component } from "react";

// Components
import EachProject from "./EachProject";

import projects from '../data/projects'

// import ParticlesBg from "particles-bg";

//css
import "../Styles/Work.css";

class Works extends Component {
  constructor(props) {
    super(props);

    //this.state = {
    //  url: "",
    //};
    this.loadProjects()
    this.loadProjects = this.loadProjects.bind(this);
  }

  loadProjects(){
      return projects.map((item, index) => {
        return <div key={index}>
            <h3 className="projectYear">{item.year}</h3>
          <ul>
            {item.projects.map((project, pIndex) => {
              //project contains each project deatils.
              //console.log(project)
              return <EachProject
                        projTitle={project['title']}
                        projDesc={project['desc']}
                        link={project['link']}
                        confidential ={project['confidential']}
                        tags={project['tags']}
                        url={project['url']}
                        team={project["team"]}
                      />
            })}
          </ul>
        </div>
      })
  }


  render() {
    return (
      <div className="work-content mainContentWrappers">
        <ul className="projectUl">
        {this.loadProjects()}
        </ul>
      </div>
    );
  }
}

export default Works;
