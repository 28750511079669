import React, { Component } from "react";

//Css
import "../Styles/EachProject.css"


class EachProject extends Component {
  render() {
    const {projTitle, projDesc, link, tags, url, confidential, team} = this.props;

    return (
      <div className="workWrapper">
        {/*<img src={thumbNail} alt={projTitle} className="workThumbnail" id="projectLogo"/>*/}
        <div className="work-details-wrapper">
          <div className="work-title">
            {link ? ( <a className="projLink" target="_blank" rel="noreferrer" href={link}>{projTitle}</a>) : projTitle}
          </div>
          <div className="work-desc">
            {projDesc}
          </div>

          <div className="tags">
          {
            tags.map((v)=>(
              <button className="tagSpan">{v}</button>
            ))
          }
          </div>

          {
            confidential ?
            <button className="buttonLink confidentialCodes">
              <i className="fas fa-grin-beam-sweat"/>source code unavailable
            </button>
            :
            <a target="_blank" rel="noreferrer" href={url}  className="buttonLink">
              <i className="fab fa-github"/>&lt;source code/&gt;
            </a>
          }

          {
            team &&
            <div className="collaborators">
              <div className="collabHeading">Collaborators:</div>
              <div>
              {
                team.map((eachMember, idx) => (
                  <a href={eachMember.url} target="_blank">{eachMember.name}{idx+1 != team.length && "," } </a>
                ))
              }
              </div>
            </div>
          }

        </div>
        {/*<i>Work in progress</i>*/}
      </div>
    );
  }
}

export default EachProject;