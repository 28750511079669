//Oldest to newest

const experienceData = [
    {
        jobPosition:"Watchroom Operator (NSF)",
        company:"Singapore Civil Defence Force",
        from: "October 2020",
        to: "August 2022",
    },
    {
        jobPosition:"Full Stack Developer",
        company:"JSB Tech PTE LTD",
        from: "Sept 2019",
        to: "Mar 2020",
    },
]

export default experienceData;