const toolsSet = [
  "NodeJs",
  "Flutter",
  "ASP.NET",
  "jQuery",
  "Joomla",
  "Wordpress",
  "HTML & CSS",
  "SailsJS",
  "Stripe",
  "RestAPI",
  "MongoDB",
  "XAMPP",
  "mySQL",
  "Postman",
  "Firebase",
  "Authy/Twllio",
]

export default toolsSet;