import React, { Component } from "react";
import Typed from 'react-typed';
import "../Styles/Home.css";


class Home extends Component {

  render() {
    //Can be used
    // const displayName = "Sean";
    // const desc = "Full stack web developer from <span>Singapore</span>"
    return (
      <div className="home-wrapper">
        <div className="home-content">
          <section className="title home-description">
            <Typed
            strings={[
              "Hello", "你好", "Nei hou","Li ho",
            ]}
            typeSpeed={150}
            backSpeed={150}
            loop/> I'm&nbsp;<span>Sean</span>
          <br />
            Year 2 student in Computing Science&nbsp; <br />
            <span id="Singapore">Singapore Institute of Technology</span>
          </section>
          <div className="bg"></div>
        </div>
      </div>
    );
  }
}
export default Home;
