import React, { Component } from "react";
import { HashRouter, Redirect, Route } from "react-router-dom";

//Components
import Home from "./Components/Home";
import NavBar from "./Components/Navbar";
import Skills from "./Components/Skills";
import Works from "./Components/Works";
import Contact from "./Components/Contact";

import {
  // spring,
  AnimatedSwitch,
} from "react-router-transition";

// Css
import "./Styles/Main.css";

class App extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <NavBar />
          <AnimatedSwitch
            /* atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles} */

            atEnter={{ offset: 100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateY(${styles.offset}%)`,
            })}
            className="switch-wrapper"
          >
            {/*<Route exact path="/">
            <Redirect to="/home" />
          </Route>*/}
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact path="/home" component={Home} />
            <Route exact path="/skills" component={Skills} />
            <Route exact path="/works" component={Works} />
            <Route exact path="/contact" component={Contact} />
          </AnimatedSwitch>
        </HashRouter>
      </div>
    );
  }
}

export default App;
