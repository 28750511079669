
const projects = [
    {
        year: '2023',
        projects:[
            {

                title:"Health Companion App",
                link: "https://healthcompanion.seankzw.com",
                url:"https://github.com/seankzw/inf2003-dbproject",
                desc: "School project based Ruby on Rails, using Relational and Non-relational DBMS",
                tags: ["Ruby on Rails", "MongoDB", "PostgreSQL", "School"],
                //active: false,
                confidential: false,
                team:[
                    {
                        name:"Jasper Tan",
                        url:"https://github.com/jaspertanlol",
                    },
                    {
                        name:"Peter Goh",
                        url:"https://github.com/x3Grim",
                    },
                    {
                        name:"Steve Loo",
                        url:"https://github.com/Siongyu",
                    },
                    {
                        name:"Timothy Neam",
                        url:"https://github.com/timneam",
                    },
                    {
                        name:"Cheng En",
                        url:"https://github.com/ACED07",
                    },
                    {
                        name:"Sean Koh",
                        url:"https://github.com/seankzw",
                    },
                ]
            },
            {
                title:"Shortest Route (Dijkstra)",
                url:"https://github.com/seankzw/Shortest_Path_Dijsktra",
                desc:"A school project to get the shortest route from point A to point B",
                tags: ["Python", "Tkinter", "Geopy", "School"],
                //active: false,
                confidential: false,
                team:[
                    {
                        name:"Jasper Tan",
                        url:"https://github.com/jaspertanlol",
                    },
                    {
                        name:"Peter Goh",
                        url:"https://github.com/x3Grim",
                    },
                    {
                        name:"Steve Loo",
                        url:"https://github.com/Siongyu",
                    },
                    {
                        name:"Veleon Lim",
                        url:"https://github.com/veleonlim",
                    },
                    {
                        name:"Sean Koh",
                        url:"https://github.com/seankzw",
                    },
                ]
            },
            {
                title:"ATM App",
                desc:"Java project that does basic ATM functionalities",
                tags:["Java","Java Spring","Java Swing", "School", "IoT"],
                confidential: false,
                team:[
                    {
                        name:"Jasper Tan",
                        url:"https://github.com/jaspertanlol",
                    },
                    {
                        name:"Peter Goh",
                        url:"https://github.com/x3Grim",
                    },
                    {
                        name:"Steve Loo",
                        url:"https://github.com/Siongyu",
                    },
                    {
                        name:"Cheng En",
                        url:"https://github.com/ACED07",
                    },
                    {
                        name:"Sean Koh",
                        url:"https://github.com/seankzw",
                    },
                ]
            }
        ]
    },
    {
        year: '2022',
        projects: [
            {
                title:"Tic tac toe",
                url:"https://github.com/seankzw/1103tictaetoe",
                desc:"Simple tic tac toe game with minimax algorithm",
                tags: ["C language","Raylib", "School"],
                confidential: false,
                team:[
                    {
                        name:"Jasper Tan",
                        url:"https://github.com/jaspertanlol",
                    },
                    {
                        name:"Peter Goh",
                        url:"https://github.com/x3Grim",
                    },
                    {
                        name:"Cheng En",
                        url:"https://github.com/ACED07",
                    },
                    {
                        name:"Sean Koh",
                        url:"https://github.com/seankzw",
                    },
                ]
            }
        ]
    },
    {
        year: '2021',
        projects: [
            {
                title: 'Rota app',
                //url: "https://github.com/seankzw/seankzw.github.io",
                desc: "A app to ease fire station's daily life",
                tags: ['Flutter','Firebase', 'Android'],
                //active: true
                confidential : true //Maybe ?
            },
            {
                title: 'Meal sheet app',
                //url: "https://github.com/seankzw/seankzw.github.io",
                desc: "Built to ease the process of food orders",
                tags: ['Glideapps','Google Sheets'],
                //active: true
                confidential : true //Maybe ?
            },
            {
                title: 'Incident report generator',
                //url: "https://github.com/seankzw/seankzw.github.io",
                desc: "Automate calculation and generate reports",
                tags: ['Flutter', 'Web'],
                //active: true
                confidential : true //Maybe ?
            },
            {
                title: 'Cheetask',
            //url: "https://github.com/seankzw/seankzw.github.io",
                desc: "Cheetah to Cheetask, get it ? A quick todo app !",
                tags: ['Flutter', 'Android','IOS'],
                //active: true
                confidential : true //Maybe ?
            },

        ]
    },
    {
        year: '2020',
        projects: [
            {
                title: 'seankzw.com',
                link: "https://www.seankzw.com",
                url: "https://github.com/seankzw/portfolio",
                desc: "My personal portfolio",
                tags: ['ReactJS','CSS', 'Vercel']
                //active: true
                //confidential : true //Maybe ?
            },
            {
                title: 'Volunteer me!',
                //link: "https://seankzw.github.io",
                url: "https://github.com/seankzw/volunteer_me",
                desc: "An android application built for final year project in Polytechnic",
                tags: ['Android Studio','Java', 'Android']
                //active: true
                //confidential : true //Maybe ?
            },

        ]
    },
    {
        year: '2019',
        projects: [
            {
                title: 'Health For All',
                //link: "tps://seankzw.github.io",
                url: "https://github.com/seankzw/health_for_all",
                desc: "A PHP based project - easing appointments in hospital",
                tags: ['PHP','HTML', 'CSS', "JS"],
                //active: true
                confidential : false //Maybe ?
            },

        ]
    }
]

export default projects;
